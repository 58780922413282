<template>
  <div>
    <div class="text-center d-flex flex-column align-center">
      <v-img
        :src="src"
        class="cursor-pointer"
        max-width="80px"
        contain
        @click="openDialog"
      />
      <span class="subtitle-1 grey--text text--darken-3">{{ title }}</span>
      <span class="caption grey--text">(Clique para expandir)</span>
    </div>
    <v-dialog v-model="dialog" :max-width="maxWidth">
      <v-card class="pa-5">
        <div class="">
          <div style="padding-bottom: 10px; display: block">
            <back-button color="grey" @click="dialog = false" />
            <span class="subtitle-1">{{ title }}</span>
          </div>
        </div>
        <v-img :src="src" cover />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ImageExpansion",
  props: {
    src: {
      type: String,
      default: "",
    },
    maxWidth: {
      default: 400,
    },
    title: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    openDialog() {
      this.dialog = true;
    },
  },
};
</script>

<style scoped></style>
